import "./Burn.css";
function remove(el) {
    if (!el) {
        return;
    }
    el.classList.add("charr");
    el.classList.add("transition");
}


function getRandomElement(shouldBeVisible) {
    const elements = document.querySelectorAll('.burn');
    let validElements: any = [];

    if (shouldBeVisible) {
        elements.forEach(element => {
            const rect = element.getBoundingClientRect();
            const isVisible = (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );

            if (isVisible) {
                validElements.push(element);
            }
        });
    } else {
        validElements = elements; //all elements
    }

    if (validElements.length === 0) {
        validElements = elements;
    }


    const randomIndex = Math.floor(Math.random() * validElements.length);
    const randomElement = validElements[randomIndex];
    console.log('Got random element', randomElement);
    
    return randomElement;
}


function removeAllFlames() {
    const flames = document.querySelectorAll('.flame');
    flames.forEach(flame => {
        flame.remove();
    });

    const glows = document.querySelectorAll('.glow');
    glows.forEach(glow => {
        glow.remove();
    });

    const burns = document.querySelectorAll('.burn');
    burns.forEach(burn => {
        burn.classList.remove('charr');
        burn.classList.remove('transition');
    });

}


//https://codepen.io/freedommayer/pen/vYRrarM
function addFlame(element) {
    const flames = Math.floor(Math.random() * 5) + 1;

    for (let i = 0; i < flames; i++) {
        const randomEffect = Math.floor(Math.random() * 5) + 1;
        const flameElement = document.createElement('div');
        flameElement.setAttribute('class', 'flame');
        const glowElement = document.createElement('div');
        glowElement.setAttribute('class', 'glow');

        flameElement.style.setProperty(
            'transform',
            `scale(${randomEffect})
            translate(-13px, -15px)`,
        );
        
        
        glowElement.style.setProperty(
        'opacity',
        '' + randomEffect * 0.1,
        );
        
        const error = 6;
        const randomTop = (Math.random() * error) - 4 + 'em';
        const randomLeft = (Math.random() * error) - 4 + 'em';
        const randomRight = (Math.random() * error) - 4 + 'em';
        const randomBottom = (Math.random() * error) - 4 + 'em';
          
        flameElement.style.setProperty('top', `${randomTop}`);
        flameElement.style.setProperty('left', `${randomLeft}`);
        flameElement.style.setProperty('right', `${randomRight}`);
        flameElement.style.setProperty('bottom', `${randomBottom}`);
        glowElement.style.setProperty('top', `${randomTop}`);
        glowElement.style.setProperty('left', `${randomLeft}`);
        glowElement.style.setProperty('right', `${randomRight}`);
        glowElement.style.setProperty('bottom', `${randomBottom}`);

        element.appendChild(flameElement);
        element.appendChild(glowElement);
    }


}

function burnRandomElement(burnOnlyVisibleElement = false) {
    const randomElement = getRandomElement(burnOnlyVisibleElement);

    if (randomElement != null) {
        addFlame(randomElement);
        remove(randomElement);
    }
}

export default burnRandomElement;