import React from 'react';
import { ImageTitledCard } from '../';
import './Projects.css';

let content = {
    'theWall': {
        'desc': `<h2>The Wall</h2>
            An app to motivate long-term progress and graphically track them across various skills.
            <br> Works on Windows, Linux and Mac.`,
        'img': 'the_wall_logo',
        'tags': ['JavaScript', 'ElectronJS', 'Sequelize DB'],
        'sourceCode' : 'https://github.com/CatalanCabbage/the-wall',
        'cta': '<a href="https://github.com/CatalanCabbage/the-wall#the-wall" target=”_blank”>Download app</a>'
    },
    'noHello': {
        'desc': `<h2>NoHello</h2>
            A visual webpage to ask your coworkers (nicely) to skip the niceties if possible.
            <br>Communicate better, asynchronously.`,
        'img': 'nohello_logo',
        'tags': ['JavaScript', 'ReactJS'],
        'sourceCode' : 'private',
        'cta': '<a href="https://dvsj.in/hello-from-my-website" target=”_blank”>Check the website out</a>'
    },
    'electronVLog': {
        'desc': `<h2>Electron vLog</h2>
            The zero-effort way to take video recordings, screenshots and timelapse images of your Electron app.`,
        'img': 'electron_vlog_logo',
        'tags': ['JavaScript', 'ElectronJS', 'WebRTC'],
        'sourceCode' : 'https://github.com/CatalanCabbage/electron-vlog'
    },
    'zeWallet': {
        'desc': `<h2>The Wallet</h2>
            Store bookmarks and excerpts with ease.
            <br>Maintain your own user account, categorize by tags, auto-populate metadata.`,
        'img': 'ze_wallet_logo',
        'tags': ['JavaScript', 'Svelte', 'Supabase', 'Serverless'],
        'sourceCode' : 'private',
        'cta': '<a href="https://zewallet.netlify.app/" target=”_blank”>Sign up now!</a>'
    },
    'checkinExtension': {
        'desc': `<h2>Zoho Check-in Chrome Extension</h2>
        Chromium browser extension to view status and check-in/check-out in Zoho People from any tab in the browser.`,
        'img': 'checkin_extension_logo',
        'tags': ['JavaScript', 'Chrome extension'],
        'sourceCode' : 'https://github.com/CatalanCabbage/zpeople-attendance-chrome-extn'
    }
}
function Projects() {
    return (
        <div id="projects">
            <section id="projects-container">
                <div className="project">
                    <ImageTitledCard name="zeWallet" img={content.zeWallet.img} desc={content.zeWallet.desc} theme="projects" tags={content.zeWallet.tags} sourceCode={content.zeWallet.sourceCode} cta={content.zeWallet.cta}/>
                </div>
                <div className="project">
                    <ImageTitledCard name="noHello" img={content.noHello.img} desc={content.noHello.desc} theme="projects" tags={content.noHello.tags} sourceCode={content.noHello.sourceCode} cta={content.noHello.cta}/>
                </div>
                <div className="project">
                    <ImageTitledCard name="theWall" img={content.theWall.img} desc={content.theWall.desc} theme="projects" tags={content.theWall.tags} sourceCode={content.theWall.sourceCode} cta={content.theWall.cta}/>
                </div>
                <div className="project">
                    <ImageTitledCard name="checkinExtension" img={content.checkinExtension.img} desc={content.checkinExtension.desc} theme="projects" tags={content.checkinExtension.tags} sourceCode={content.checkinExtension.sourceCode}/>
                </div>
                <div className="project">
                    <ImageTitledCard name="electronVLog" img={content.electronVLog.img} desc={content.electronVLog.desc} theme="projects" tags={content.electronVLog.tags} sourceCode={content.electronVLog.sourceCode}/>
                </div>
                {/*
                <div className="project">Plus or minus one</div> 
                */}
                <footer>(And many other tiny contraptions!)</footer>
            </section>
        </div>
    );
}
export default Projects;