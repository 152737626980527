import React from "react";
import { Redirect } from 'react-router-dom';

function BadTwitter () {
    // If user agent is Twitterbot, redirect to twitter
    if (navigator.userAgent === 'Twitterbot') {
        //Do a permanent redirect
        return <Redirect to="https://twitter.com/dvsj_in" />
    } else {
        window.location.href = 'https://dvsj.in';
    }
}

export default BadTwitter;