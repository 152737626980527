import React from 'react';
import { Link } from 'react-router-dom';
import './OptionCards.css';
import * as images from '../images';

const cardProps: {[index: string]: any} = {
    'work': {
        title: 'WORK',
        desc:  'My bread and butter. With an occasional side of bacon.'
    }, 'blog': {
        title: 'BLOG',
        desc:  'Ideas, observations and lessons. Some I reflect on, others I digest.'
    }, 'about': {
        title: 'ABOUT',
        desc:  'Sugar, spice, and a curious cat in disguise.'
    }
}

function OptionCards() {
    return (
        <nav className="OptionCards">
            <Link to="/work" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                <GetOptionCard id="work"/>
            </Link>
            <Link to="/blog" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                <GetOptionCard id="blog"/>
            </Link>
            <Link to="/about" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                <GetOptionCard id="about"/>
            </Link>
        </nav>
    )
}

function GetOptionCard(props: { id: string }) {
    let cardProp: {[index: string]: string} = cardProps[props.id];
    //HeaderImages are named 'id_header'
    let headerImage = props.id + '_header';
    return (
        <div className="OptionCard">
            <div className="OptionCard__img-container">
                <img src={images[headerImage]} className="OptionCard__img" alt=""></img>
            </div>
            <div className="OptionCard__bottom">
                <div className="OptionCard__title">
                    {cardProp.title}
                </div>
                <div className="OptionCard__desc">
                    {cardProp.desc}
                </div>
            </div>
        </div>
    );
}

export default OptionCards;