import React from 'react';
import './ImageTitledCard.css';
import * as images from '../images';


function ImageTitledCard(props: { name: string, img: string, desc: string, theme?: string, tags: string[], sourceCode?: string, cta?: string}) {
    return (
        <div>
            <GetImageTitledCard id={props.name} img={props.img} desc={props.desc} theme={props.theme} tags={props.tags} sourceCode={props.sourceCode} cta={props.cta}/>
        </div>
    )
}

function GetImageTitledCard(props: { id: string, img: string, desc: string, theme?: string, tags: string[], sourceCode?: string, cta?: string}) {
    let theme = props.theme ? props.theme : '';
    let tagsElement = '';
    props.tags.forEach(tag => {
        tagsElement += `<span class="tag">${tag}</span>`
    });
    let sourceCodeElement;
    if (props.sourceCode) {
        if (props.sourceCode === 'private') {
            sourceCodeElement = 
                <div className="ImageTitledCard__footer">
                    <img title="Private repo, source code unavailable" className="ImageTitledCard__sourceCode" alt="Private repo, source code unavailable" src="github-icon-grey.png"></img>
                </div>
        } else {
            sourceCodeElement =
                <div className="ImageTitledCard__footer">
                    <a href={props.sourceCode} target="_blank" rel="noopener noreferrer">
                        <img title="View source code" className="ImageTitledCard__sourceCode" alt="Link to source code" src="github-icon-light.png"></img>
                    </a>
                </div>
        }
    }
    
    return (
        <div className={"ImageTitledCard " + theme} id={props.id}>
            {sourceCodeElement ? sourceCodeElement : null}
            <img src={images[props.img]} className={`ImageTitledCard__img ${theme} ${props.id}`} alt=""></img>
            <div className="ImageTitledCard__desc" dangerouslySetInnerHTML={{__html: props.desc}} />
            {props.cta ? <div className="ImageTitledCard__cta" dangerouslySetInnerHTML={{__html: props.cta}}></div> : null}
            <div className="ImageTitledCard__tags" dangerouslySetInnerHTML={{__html: tagsElement}}/>
        </div>
    );
}

export default ImageTitledCard;