import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/airbnb.css";
import './Time.css';
import { useState } from "react";
import moment from 'moment-timezone';
import React from "react";


function getTimeData(label, selectedTime, timeZone) {
    const format = 'YYYY/MM/DD HH:mm:ss ZZ';
    const timeMoment = moment(selectedTime, format).tz(timeZone);
    const timeText = timeMoment.calendar()
    const currentHour = parseInt(timeMoment.format("HH"));    
    let timeLabel = '';
    let labelColor = '';
    if (currentHour >= 6 && currentHour < 8) {
        timeLabel = '⨯ too early';
        labelColor = 'red';
    } else if (currentHour >= 8 && currentHour < 18) {
        timeLabel = '✔ good time';
        labelColor = 'green';
    } else if (currentHour >= 18 && currentHour < 21) {
        timeLabel = '✔ evening';
        labelColor = 'green';
    } else if (currentHour >= 21 && currentHour < 23) {
        timeLabel = '⨯ late, how important is this?';
        labelColor = 'orange';
    } else {
        timeLabel = '⨯ night';
        labelColor = 'red';
    }
    
    return {
        'label': label,
        'labelColor': labelColor,
        'time': timeText,
        'timeLabel': timeLabel
    }
}

export default function Time() {
    const [selectedTime, setSelectedTime] = useState(new Date());
    
    let timeData = [
        getTimeData('Us & Vijay (IST)', selectedTime, 'Asia/Kolkata'),
        getTimeData('Ani (PST)', selectedTime, 'America/Los_Angeles'),
        getTimeData('Karan (EST)', selectedTime, 'America/New_York')
    ];
    return (
      <div id='time-translation-container'>
        <div id='time-translation'>
          <h1>Is it a good time?</h1>
          <div className="timezone-text">Your timezone: {Intl.DateTimeFormat().resolvedOptions().timeZone}</div>
          <div id="selected-time">
              <Flatpickr
                  options={{
                      defaultDate: selectedTime,
                      enableTime: true,
                      dateFormat: "d-m-Y H:i",
                      altInput: true,
                      altFormat: "F j, Y - H:i K",
                      onChange: ([date]) => setSelectedTime(date),
                  }}
              />
          </div>
          <div id="results">
              <table> 
                  <tbody>
                      {timeData.map((item, index) => (
                          <tr key={index}>
                              <td>{item.label}</td>
                              <td>{item.time}</td>
                              <td className={'label-color-' + item.labelColor}>{item.timeLabel}</td>
                          </tr>
                      ))}
                  </tbody>
              </table>
          </div>
        </div>
      </div>
    );
}
