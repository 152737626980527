import React, { useRef, useState, useEffect } from "react";
import "./CopyableLink.css";
import * as images from '../../../assets/images';

const CopyableLink = () => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [buttonText, setButtonText] = useState("Copy 📋");
    const [inputText, setInputText] = useState('hello-there!');

    const isValidLink = () => {
        return inputText.toLowerCase().indexOf('call') !== -1 || inputText.toLowerCase().indexOf('hello') !== -1;
    };

    const addValidityClass = (existingClass) => {
        return (existingClass) + ' ' + (isValidLink() ? 'correct' : 'incorrect');
    };

    useEffect(() => {
        const text = inputRef.current?.value || '';
        setInputText(text);
    }, [inputText]);


    const copyToClipboard = () => {
        if (isValidLink()) {
            navigator.clipboard.writeText('https://dvsj.in/' + encodeURIComponent(inputText.replaceAll(' ', '_'))); 
        } else {
            navigator.clipboard.writeText('https://dvsj.in/hello-there-georgie!')
        }
        setButtonText("Copied!");

        // Revert button text after 3 seconds
        setTimeout(() => {
            setButtonText("Copy📋");
        }, 3000);
    };

    const handleInputChange = () => {
        setButtonText("Copy📋");
        const text = inputRef.current?.value || '';
        setInputText(text);
    };

    const customLinks = [
        'helloThere',
        'hello-to-you-too!',
        'hello-darkness-my-old-friend',
        'hellooFromTheOtherSideeee',
        'call-me-maybe',
        'pls👏dont👏call',
        'nohello',
        'call-me',
        'yep.call_me_anytime.',
        'HELLo',
        'hello.....'
    ]
    const randomizeLink = () => {
        const randomIndex = Math.floor(Math.random() * customLinks.length);
        const randomLink = customLinks[randomIndex];
        setInputText(randomLink);
        if (inputRef.current) {
            inputRef.current.value = randomLink;
        }
    }

    return (
        //Ref: https://codepen.io/aaroniker/pen/dybMVMB
        <div>
            <h1>Want to share this?</h1>
            <div>
                <span>Craft a custom link. </span>
                <span className={addValidityClass('link-condition')}>Just mention "call" or "hello" in the URL.</span>
            </div>
            <div>
                <div className="form-group-container">
                    <span className={addValidityClass('form-group')}>
                        <span>https://dvsj.in/</span>
                        <input ref={inputRef} className={addValidityClass('form-field')} type="text" placeholder='hello-there-georgie!' onKeyUp={handleInputChange}/>
                        <span className='copy-button' onClick={copyToClipboard}>
                            {buttonText}
                        </span>
                    </span>
                    <div id='surprise-me' onClick={randomizeLink}>
                        <img id='surprise-img' src={images.dice} alt='surprise' />
                        {/* <div id='surprise-text'>Surprise me</div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CopyableLink;
