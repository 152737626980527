import React, { useEffect, useState } from 'react';
import './Toast.css';


function Toast(props: { content: string, showToastInput: boolean }) {
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if (props.showToastInput) {
      setShowToast(prevShowToast => {
        if (!prevShowToast) {
          return true;
        }
        return prevShowToast;
      });
    } else {
      setShowToast(false);
    }
  }, [props.showToastInput]);

  if (!showToast) {
    return null;
  }

  return (
      <span className='toast'>{props.content}</span>
  );
}

  

export default Toast;