import React from 'react';
import './OpenGraph.css';

function OpenGraph () {
    //https://www.opengraph.xyz
    return (
        <div id='open-graph'>
            Hello, this is OG
            <FacebookPreview></FacebookPreview>
        </div>
    )
}


function FacebookPreview() {
    return (
        <div className="link-preview">
        <a href="https://example.com" className="preview-link">
            <div className="preview-image">
            <img src="/cover-picture.png" alt="Link Preview"/>
            </div>
            <div className="preview-content">
            <h3 className="preview-title">This is a title</h3>
            <p className="preview-description">desc</p>
            </div>
        </a>
        </div>

    )
}

export default OpenGraph;