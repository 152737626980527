/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useRef, useState } from 'react';
import './PossibleFonts.css';


function PossibleFonts() {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [textareaText, setTextareaText] = useState('Carpe diem. Seize the day, boys.');
  const [showToast, setShowToast] = useState(false);
  
  const handleCopyClick = (content: string) => {
    navigator.clipboard.writeText(content);
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 2000); // Duration in milliseconds
  };

  const handleTextareaChange = () => {
    if (textareaRef.current) {
      const value = textareaRef.current.value;
      setTextareaText(value);
    }
  };

  const fontNames = [
    'Arial, sans-serif','Arial Black, Gadget, sans-serif','Arial Narrow, sans-serif','Arial Rounded MT Bold, sans-serif','Avant Garde, sans-serif','Baskerville, serif','Big Caslon, serif','Bodoni MT, serif','Bookman Old Style, serif','Book Antiqua, Palatino, serif','Calisto MT, serif','Cambria, serif','Century Gothic, sans-serif','Comic Sans MS, cursive, sans-serif','Copperplate, Copperplate, serif','Courier, monospace','Courier New, monospace','Franklin Gothic Medium, sans-serif','Futura, sans-serif','Garamond, serif','Geneva, sans-serif','Georgia, serif','Gill Sans, Gill Sans MT, sans-serif','Helvetica, sans-serif','Hoefler Text, Garamond, serif','Impact, Charcoal, sans-serif','Lucida Bright, Georgia, serif','Lucida Console, Monaco, monospace','Lucida Grande, sans-serif','Lucida Sans Unicode, sans-serif','Meiryo UI, sans-serif','Microsoft Sans Serif, sans-serif','Monaco, monospace','Optima, sans-serif','Palatino, serif','PT Sans, sans-serif','Rockwell, serif','Segoe UI, Tahoma, Geneva, Verdana, sans-serif','Tahoma, Geneva, sans-serif','Times, Times New Roman, serif','Times New Roman, Times, serif','Trebuchet MS, Helvetica, sans-serif','Verdana, Geneva, sans-serif','Yu Gothic, sans-serif'
    ]

  const fontElements = fontNames.map((each) => (
    <tr className='font-entry' key={each} style={{ fontFamily: each }}>
        <td className='font-column'>
        <span className='font-name' onClick={() => handleCopyClick(each)} title='Copy'>
          <span className='copy-symbol'> 📋 </span>
          {each}
        </span>
        </td>
      <td key={each}>
        {textareaText} <br/>
        <b>{textareaText}</b><br/>
        <i>{textareaText}</i>
      </td>
    </tr>
  ));

  return (
    <div id='possible-fonts'>
      <h1>All possible websafe fonts</h1>
      <textarea
        ref={textareaRef}
        id='font-names'
        defaultValue={textareaText}
        onChange={handleTextareaChange}
      ></textarea>
      <table id='font-entries' style={{ borderCollapse: 'collapse' }}>
        <tbody>{fontElements}</tbody>
      </table>
      {showToast && <div className='toast'>Copied to clipboard</div>}
    </div>
  );
}

export default PossibleFonts;