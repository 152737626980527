import React from 'react';
import './Footer.css';
// import { useInit, useQuery, tx, transact } from "@instantdb/react";

const APP_ID = "f354f54d-3f92-4599-bbaa-1bfc49f587ff";


function Footer() {
    let welcomeText = '';
    // const [isLoading, error] = useInit({
    //     appId: APP_ID,
    //     websocketURI: "wss://instant-server-clj.herokuapp.com/api/runtime/sync",
    //     apiURI: "https://instant-server-clj.herokuapp.com/api"
    // });
    const isLoading = true;
    if (isLoading) {
        //No handling needed for now
        console.log("Loading!");
        return (
            <div id="footer">
                <div id="visitors-count">
                    {welcomeText}
                </div>
            </div>
        );
    }
    // if (error) {
    //     //No handling needed for now
    //     console.log(error);
    //     return (
    //         <div id="footer">
    //             <div id="visitors-count">
    //                 {welcomeText}
    //             </div>
    //         </div>
    //     );
    // }

    return <FooterContent />
}

let visitorCountIncremented = false;
function FooterContent() {
    // let visitorCount = 0;
    // let visitorCountResult;
    
    // visitorCountResult = useQuery({
    //     commonData : {
    //         $: {where : {id : 'visitors'}} 
    //     }
    // });
    
    // console.log(JSON.stringify(visitorCountResult));
    // if (visitorCountResult != null) {
    //     visitorCount = visitorCountResult?.commonData[0]?.count;
    // }
    // if (visitorCount == null) {
    //     visitorCount = 0;
    // } else {
    //     if (!visitorCountIncremented) {
    //         try {
    //             transact([
    //                 tx.commonData['visitors'].update({'count' : visitorCount + 1})
    //             ])
    //             visitorCountIncremented = true;
    //         } catch (err) {
    //             console.log(err);        
    //         }
    //     }
    // }

    let visitorCount = 912;
    return (
        <div id="footer" title="🗲">
            <div id="visitors-count" className="shake">
                <div>You are visitor #<span id="visitors-count__number">{visitorCount}</span>. Welcome!</div>
                {/* <div id="powered-by"><a href="https://www.instantdb.com" target="_blank" rel="noopener noreferrer">Powered by InstantDB<span role="img" aria-label="Lightning bolt" className="yellow">⚡</span></a></div> */}
            </div>
        </div>   
    )
}

export default Footer;