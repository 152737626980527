import React from 'react';
import { ImageTitledCard } from '../';
import './Work.css';
import './Projects';
import Projects from './Projects';

let content = {
  'zoho': { 
    'desc': 
      `<h2>Zoho Corporation ~ Member Technical Staff</h2>
      <br/>Ideating, building and shaping various aspects of 
      <br/> <b>ZohoCorp ManageEngine's UEMS suite of products</b>,
      <br/>including UAC and Licensing.
      <br/>---
      <br/> <br/> <i>Takes responsibility from inception to post-shipping analysis,
      <br/> keeping in mind paradigms of stability, security and scalability.</i>`
    ,
  'tags': ['Java', 'PostgreSQL', 'MSSQL', 'Jersey'],
  'img': 'zoho_logo'
  }, 
  'alloan': { 
    'desc': 
      `<h2>alloan.ai ~ Founding Engineer</h2>
      <br/>Building a virtual assistant for the
      <br/><b>Asset based finance</b> market.
      <br/>Don't worry, it isn't as complicated as it sounds.
      <br/>Ask me about it.
      <br/>---
      <br/> <br/> <i>Does anything tech that needs to be done. 
      <br/>Backend, sometimes Frontend, Security, Infrastructure,
      <br/>Generative AI, Architecture, Compliance, devOps and lots more.</i>`
    ,
  'tags': ['Python', 'Azure', 'NoSQL', 'ReactJS'],
  'img': 'alloan_logo'
  }
}

function Work() {
  let scrollElementId = 'divider';
  setTimeout(() => {
    document.getElementById(scrollElementId)?.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'});
  }, 200)
  return (
    <div id="work-container" className='slow-intro'>
        <div id="experiences-container">
          <section id="alloan-container" className="work-entry">
            <ImageTitledCard name="alloan" img={content.alloan.img} desc={content.alloan.desc} tags={content.alloan.tags}/>
          </section>
          <section id="zoho-container" className="work-entry">
            <ImageTitledCard name="zoho" img={content.zoho.img} desc={content.zoho.desc} tags={content.zoho.tags}/>
          </section>
        </div>
        <div className="direction" title="Professional work">
          <div id="arrow-top"></div>
          <div id="work-caption" className="caption">My bread and butter</div>
        </div>
        <div id="divider"></div>
        <div className="direction" title="Informal work">
          <div id="projects-caption" className="caption">The occasional side of bacon</div>
          <div id="arrow-bottom"></div>
        </div>
        <section id="projects-container">
          <Projects/>
        </section>
    </div>
  );
}
export default Work;