/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import './NoHello.css';
import ChatScreen from './ChatScreen'
import CopyableLink from "./CopyableLink";
import * as images from '../../../assets/images';

function NoHello () {
    return (
        <div id='nohello'>
            <NoText></NoText>
            <NoCall></NoCall>
            <FooterPage></FooterPage>
        </div>
    )
}

function popupHoverAction(event) {
    const popupElements = document.getElementsByClassName('title-popover') as HTMLCollectionOf<HTMLElement>;
    
    const leftDistance = (event.clientX + 0) + 'px';
    const topDistance = (event.clientY + 17) + 'px';
    for (let i = 0; i < popupElements.length; i++) {
        const paraElement = popupElements[i].querySelector('p');
        if (paraElement) {
            paraElement.style.left = leftDistance;
            paraElement.style.top = topDistance;
        }
    }
}




function FooterPage() {
    return(
        <div id='footer-page'>
            <div className='explanation burn relative'>
                <h1 className='burn relative'>But why?</h1>
                We try to be polite by not jumping right into the request, like we would do in person or on the phone; but chat is neither of those things and it <i>feels like putting someone on hold after just saying "Hello"</i>.  
            </div>
            <div className='share-container'>
                <CopyableLink></CopyableLink>
            </div>
            <div>
                <h1>Inspired by</h1>
                <a href='https://nohello.net'>nohello.net</a> and <a href='https://www.nohello.com'>nohello.com</a>
            </div>
            <div className='personal-links'>
                <span className="title-popover" onMouseMove={popupHoverAction} onMouseLeave={popupHoverAction}>
                    <a href='https://dvsj.in' rel="noopener noreferrer" target="_blank">
                        <img className='logo' src={images.siteLogo} alt='logo'></img>
                    </a>
                    <p>Visit my website</p>
                </span>
                <span className="title-popover" onMouseMove={popupHoverAction} onMouseLeave={popupHoverAction}>
                    <a href='https://blog.dvsj.in' rel="noopener noreferrer" target="_blank">
                        <img className='logo' src={images.tablet} alt='logo'></img>
                    </a>
                    <p>Read my blog</p>
                </span>
                <span className="title-popover" onMouseMove={popupHoverAction} onMouseLeave={popupHoverAction}>
                    <a href='https://twitter.com/dvsj_in' rel="noopener noreferrer" target="_blank">
                        <img className='logo' src={images.twitterLogo} alt='logo'></img>
                    </a>
                    <p>Keep in touch</p>
                </span>
            </div>
        </div>
    )
}

function NoText() {
    return (
        <div id='no-text'>
            <section className='do-this'>
                <div className='heading'>
                    <h1 className='burn relative'>Sending a message?</h1>
                </div>
                <div className='chat-section'>
                    <ChatScreen isChat={true} isDo={false}></ChatScreen>
                </div>
                <div className='points'>
                    <ul className='points'>
                        <li><span aria-label="wrong" role="img">❌</span> You wait for me to notice your message, twice.</li>
                        <li><span aria-label="wrong" role="img">❌</span> Maybe I take a while and you forget what you wanted to ask me!</li>
                    </ul>
                </div>
            </section>

            <section className='dont-do-this'>
                <div className='heading burn relative'>
                    <h2 className='burn relative'>Add context to your first message.</h2>
                </div>
                
                <div className='chat-section'>
                    <ChatScreen isChat={true} isDo={true}></ChatScreen>
                </div>
                <div className='points burn relative'>
                    <ul>
                        <li><span aria-label="correct" role="img">✅</span> I start thinking about your question immediately.</li>
                        <li><span aria-label="correct" role="img">✅</span> You get a response much, much faster!</li>
                    </ul>
                </div>
            </section>
        </div>
    )
}

function NoCall() {
    return (
        <div id='no-call'>
            <section className='do-this'>
                <div className='heading burn relative'>
                    <h1 className='burn relative'>Calling me?</h1>
                </div>
                <div className='chat-section'>
                    <ChatScreen isChat={false} isDo={false}></ChatScreen>
                </div>
                <div className='points burn relative'>
                    <ul>
                        <li><span aria-label="wrong" role="img">❌</span> You have a short question but I might assume it's long, leaving it for later.</li>
                        <li><span aria-label="wrong" role="img">❌</span> I might need to look the answer up.</li>
                        <li><span aria-label="wrong" role="img">❌</span> I get anxious - is it an emergency or a casual call?</li>
                    </ul>
                </div>
              </section>

              <section className='dont-do-this'>
                <div className='heading burn relative'>
                    <h2 className='burn relative'>Ping me with context first.</h2>
                </div>
                
                <div className='chat-section'>
                    <ChatScreen isChat={false} isDo={true}></ChatScreen>
                </div>
                <div className='points burn relative'>
                    <ul>
                        <li><span aria-label="correct" role="img">✅</span> I know what it's about and how long it will take</li>
                        <li><span aria-label="correct" role="img">✅</span> I have time to check the info needed to have a productive conversation</li>
                        <li><span aria-label="correct" role="img">✅</span> I can pick up or reschedule accordingly</li>
                    </ul>
                </div>
              </section>
        </div>
    )
}

export default NoHello;