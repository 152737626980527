import React, { useRef } from 'react';
import './About.css';

function About() {
  setTimeout(() => {
    document.getElementById('about-container')?.scrollIntoView({behavior: 'smooth'});
  }, 200)

  //Expand details on clicking anywhere on the header.
  //But this means when summary is clicked, it'll open it and this will immediately close it.
  //So stop propogation to parent when summary is clicked.
  const detailsRef = useRef<HTMLDetailsElement>(null);
  const handleParentClick = () => {
    if (detailsRef.current) {
      detailsRef.current.open = !detailsRef.current.open;
    }
  };
  const handleSummaryClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };
  const linkedInReviewLink = 'https://www.linkedin.com/recs/give/?senderId=dvsj';
  // const linkedInReviewLink = 'https://www.linkedin.com/in/dvsj/details/recommendations/edit/write';

  return (
    <div id="about-container" className='slow-intro'>
      <div id="contact">
          <div id="email-text">
            <span className='contact-call-emoji'>🤙🏽</span> 
            <span>dav.is@zohomail.in</span>
          </div>
          <div className="icons">
            <a href="https://twitter.com/dvsj_in" target="_blank" rel="noopener noreferrer">
              <div className='icons__content'>
                <span id='twitter-text'>Twitter</span>
                <img className="icon" alt="Twitter link" src="/twitter-logo.png"></img>
              </div>
            </a>
          
            •
            <a href="https://www.linkedin.com/in/dvsj/" target="_blank" rel="noopener noreferrer">
              <img className="icon" alt="LinkedIn link" src="/linkedin-logo.png"></img>
            </a>
          
            •
            <a href="https://github.com/CatalanCabbage" target="_blank" rel="noopener noreferrer">
              <img className="icon" alt="GitHub link" src="/github-logo.png"></img>
            </a>
          </div>
      </div>
      <div className="review-title" onClick={handleParentClick}>
        <div>
          <span className="review-title__stars">⭐⭐⭐⭐⭐</span>
        </div>
        <div>
          <details ref={detailsRef} className="review-title__subtext">
            <summary onClick={handleSummaryClick}>Have we worked together?</summary>
            <div className="review-title__subtext_ka_subtext">Then <s>draw</s>&nbsp;
            <a className="about-review-link" href={linkedInReviewLink} target='blank' rel='noopener noreferrer'><i>rate me</i></a>
            &nbsp;like one of your French girls, DiCaprio</div>
          </details>
        </div>
      </div>
      <ReviewsGrid />
      <div id="review-footer">
        <sup>*</sup>Reviews are from&nbsp;
        <a className="about-review-link" href='https://www.linkedin.com/in/dvsj/details/recommendations' rel='noopener noreferrer' target='blank'>LinkedIn recommendations.</a> <br />
        <span className='review-footer-tldr'>"Titles"</span> are gpt-4 generated.
      </div>
    </div>
    );
  }

function ReviewsGrid() {
  const reviewsText = [
    {
      "profile_pic": "pushpa.jpg",
      "name": "Pushparaj Vijayakumar",
      "tldr": "Inspiring",
      "designation": "Software Engineer 3, Google",
      "review": `
It was a really delightful experience working alongside Davis. He brings drastic changes to the products he works as well as in people around him. 
His positive attitude and assertive thinking has really inspired me and my colleagues deeply. He is very technical person and a developer with genuine interest and work ethics. 
I would recommend him and support him for all his future endeavors.`
    },{
      "profile_pic": "arun.jpg",
      "name": "Arun Joseph",
      "tldr": "Security Expert", //Driven, Security Expert, Tech Enthusiast, Quality Contributor
      "designation": "Software Engineer 3, Google",
      "review": `
Davis is highly motivated and filled with positive vibe. 
His research into security related aspects in development suprised me. He makes detailed writeup on complex security vulnerabilities to bring security awareness. 

He is definitely one of the top performers in Zoho.`
    },{
      "profile_pic": "sriman.jpg",
      "name": "Sriman Swami",
      "tldr": "Detail-oriented",
      "designation": "Building Observability, Paypal",
      "review": `
It was a wonderful experience working alongside Davis. 
He has a deep knowledge of the product and incredible command over security aspects. Has a keen eye for details which shows how much he cares about the product. 
One of the most sincere and helpful people I've ever met. A great engineer and an impeccable mentor.`
    },{
      "profile_pic": "ajith.jpg",
      "name": "Ajith Berlin",
      "tldr": "Artistic Precision", //Artistic Precision
      "designation": "SDE, Percept Solutions Singapore",
      "review": `
I have had the pleasure of working with Davis and he is truly a unique and talented individual. 
His level of skill and expertise in his profession is nothing short of inspiring. His work is like a beautiful work of art, with a level of precision and attention to detail that is unmatched.
What I admire most about Davis is his ability to constantly amaze me with new talents and skills. He truly is a man with a million talents. 

Despite his impressive skill set, he remains incredibly humble and always willing to help others.\n\nI have learned so much from Davis and I have no doubt that I still have much more to learn. I greatly appreciate his work and I look forward to the opportunity to collaborate with him again in the future. Anyone would be lucky to have him as a part of their team.`
    },{
      "profile_pic": "jayatheertha.jpg",
      "name": "Jayateertha Guruprasad",
      "tldr": "Expert", //Expert, Insightful
      "designation": "Cybersecurity Professional, Siemens",
      "review": `
Davis's expertise in security and development is exceptional. His ability to simplify complex concepts through insightful blogs/posts is impressive.`
    },{
      "profile_pic": "butter.jpg",
      "name": "Sethu Ganapathi",
      "tldr": "Passionate", //Passionate, Dynamic, Skilled, Tech Communicator, Growth-oriented Developer
      "designation": "Lead Software Engineer, Freshworks",
      "review": `
I have worked alongside Davis for the past 4 years, and I can confidently say that he is an exceptional software developer with a genuine passion for technology. 
Davis possesses that unique blend of experience and learning acumen for the ever-evolving world of software.\n\nHe has that knack of explaining things in simple terms as well as presenting tech content in a visually pleasing way that wants us to read more.

I wholeheartedly recommend Davis as a dynamic, skilled, and growth-oriented software developer who would be an invaluable asset to any team. 
Feel free to reach out to him – you won't be disappointed😉`
    },{
      "profile_pic": "kings.jpg",
      "name": "Kingsley Reuben",
      "tldr": "Problem-solver", //Communicative, Tech-savvy, Problem-solver
      "designation": "Software Engineering Manager, ManageEngine",
      "review": `
Davis is an excellent fellow worker who has contributed so much to team in solving problems quickly and efficiently. 
Has excellent communication skills. Worked in core Windows technologies, Postgres and inbuilt Java based Zoho frameworks.
All the best.`
    },{
      "profile_pic": "sushil.jpg",
      "name": "Sushil Kumar Balasubramaniam",
      "tldr": "Inclusive Leader", //Analytical, Leadership, Motivating, Tech Wizard, Inclusive Leader
      "designation": "Member Technical Staff, Zoho",
      "review": `
I had the pleasure of working alongside Davis AKA Tech Wizard at Zoho Corporation.
He is an exceptional professional who consistently demonstrated strong work ethic and keen analytical skills with a never ending thirst towards new tech. He possesses an impressive ability to solve complex problems and communicate effectively.

Beyond technical expertise, what truly sets apart is his leadership style. 
Davis is good at bringing out the best in those around him, fostering a positive and inclusive work environment. I strongly believe Davis creates the same atmosphere wherever he goes. 
Excited to see him embark on his new chapter.`
    },{
      "profile_pic": "saduriya.jpg",
      "name": "Saduriya J",
      "tldr": "Tech Enthusiast", //Versatile, Commendable, Enthusiastic, Tech Enthusiast
      "designation": "Member Technical Staff, Zoho",
      "review": `
One of the versatile developers I came across in Zoho. 
His exemplary proficiency in communication underscores a highly commendable professional trait, contributing significantly to the team's success and his enthusiasm for embracing cutting-edge tech is truly noteworthy.`
    },{
      "profile_pic": "alex.jpg",
      "name": "Alex Jeffrin",
      "tldr": "Growth Fosterer", //Mentor, Communicative, Passionate, Transformative Mentor, Growth Fosterer
      "designation": "Member Technical Staff, Zoho",
      "review": 
`I had the privilege of working with Davis Jeffrey as a team member and mentored me. his guidance and support were instrumental in my growth and development. A man who possesses excellent communication skills and a genuine passion for mentoring others. 
I highly recommend Davis as a mentor who is dedicated, knowledgeable and committed to fostering growth. 
It was truly a transformative experience working with him.`
    },{
      "profile_pic": "sid.jpg",
      "name": "Siddharth Prince",
      "tldr": "Innovative Thinker", //Diligent, Creative, Security-focused, Natural Leader, Innovative Thinker
      "designation": "MS AI & ML, University of Limerick",
      "review": `
The first thing that comes to mind when I think about Davis professionally is his incredible work ethic. 
He is someone that is extremely passionate about what he does and strives to be the best at it. 
I've had the pleasure of working with Davis for 3 years and he has been nothing short of dedicated, consistently demonstrating a commitment to excellence. 
Davis is also a confident and natural leader and has led his team to success consistently regardless of the nature of task at hand, be it solving a critical issue or coming up with and implementing features that enhanced the product significantly.

Davis is also a very creative individual and this adds a unique dimension to his contributions. His innovative thinking and creative solutions bring a fresh perspective to every task, showcasing his ability to think outside the box. 

Developers and managers are aplenty but those that always work with security in mind at the get go are hard to come by. 
Davis is one such individual who is an advocate for good security practices in code which is a way of thinking that is ever more valuable today.

It's been an absolute privilege collaborating with someone so driven and focused on achieving outstanding outcomes. Davis' attention to detail and proactive approach significantly has and will contribute to the success of any project. 
Needless to say, he will always be an extremely valuable asset to any team he's a part of.`
    },{
      "profile_pic": "vishal.jpg",
      "name": "Vishal M",
      "tldr": "Inspiring Creator", //Communicative, Technical, Creative, Skillful Communicator, Inspiring Creator
      "designation": "Backend Developer, Zoho",
      "review": `
Working with Davis has been great. He has top-notch communication, writing, and technical skills that I've always admired. 
His side projects are super inspiring, showcasing his creativity. I'd totally recommend Davis for any project or team – he brings a fresh perspective and solid skills to the table.`
    },{
      "profile_pic": "dhanush.jpg",
      "name": "Dhanush Kumar",
      "tldr": "Quality Contributor", //Motivated, Knowledgeable, Talented, Security Expert, Quality Contributor, 
      "designation": "Member Technical Staff, Endpoint Central Cloud",
      "review": `
I have worked with Davis in various projects for past 2 years. A highly motivated, multi-talented person who always inspires me with his product knowledge and his unique perspective. His expertise in application security is incredible. 
Never failed to amaze me with his writing through his posts and blogs. One of the highly talented individual i have worked with. 
I would totally recommend Davis for any team, he consistently brings high-quality work to the table.`
    },{
      "profile_pic": "pradeep.jpg",
      "name": "Pradeep Veerapandiyan",
      "tldr": "Mindful", //Intuitive, Reliable, Mindful, Visionary Leader, Knowledge Repository
      "designation": "Product Specialist, Zoho",
      "review": `
Davis is a vital knowledge repository for the team! He possesses intuition, reliability, and mindfulness. 
Our conversation on System Designs left me impressed by not only his expertise but also the profound depth of his knowledge.
He will emerge as one of the visionary leaders of the future!`
    },{
      "profile_pic": "uma.jpg",
      "name": "Uma Priyadarsi",
      "designation": "Software Engineer, Zoho MDM",
      "tldr": "Knowledgeable", // Simplifier, Knowledgeable, Communicative, Valuable Addition
      "review": `
Davis was always a treasure trove of knowledge and technical know-how. 
He also had the unique knack of distilling these complexities into simple first principles. 
Combining all this with great communication skills made working with him always a pleasure, and also made him a valuable addition to the team.`
    },{
      "profile_pic": "nagaveda.jpg",
      "name": "Nagaveda Sai Kumar Reddy Gajjala",
      "tldr": "Hardworking", //Talented, Hardworking, Creative, Engineering Excellence, Expectation Surpasser
      "designation": "MS, University of Texas",
      "review": `
Davis is an extremely talented, hard working and creative engineer. 
During my time at Zoho corp, we worked in same team and I got to witness his working excellence. I learnt many things from him. I can strongly recommend Davis to any team. 
He will always give more than what you expect.`
    },{
      "profile_pic": "ciso.jpg",
      "name": "Bharathi Anbazhagan",
      "tldr": "Quick Learner", //Adaptable, Innovative, Diplomatic, Quick Learner, Innovative Problem-solver
      "designation": "Product Security Manager, Zoho",
      "review": `
I had the pleasure of working with Davis, and I can confidently say he's a standout professional. Davis possesses a remarkable ability to quickly grasp complex computer concepts, making him an invaluable asset in any tech-driven environment.\n\nWhat sets Davis apart is his unparalleled adaptability and individuality in problem-solving. He approaches challenges with a fresh perspective, often uncovering innovative solutions that others might overlook. 
His penchant for diving deep into subjects, akin to a true bookworm, not only enriches his knowledge but also enhances his ability to tackle diverse tasks effectively.
He consistently brings fresh insights to the table, fostering an environment of creativity and exploration within the team. 
Moreover, his adeptness at handling people with diplomacy and respect makes him a pleasure to work with.
I wholeheartedly recommend him for any role where adaptability, innovation, team-motivation, and collaboration are valued.`
    },{
      "profile_pic": "harish.jpg",
      "name": "Harish Kumaran",
      "tldr": "Encouraging", //Encouraging, Inspirational, Supportive, Encouraging, Inspirational, Supportive
      "designation": "Technical Account Manager EU, ManageEngine",
      "review": `
I had the pleasure of working with Davis. 
As a developer, Davis not only excelled in his technical expertise but also had a remarkable ability to explain complex concepts in simple terms. 
His guidance was instrumental in helping me understand the development process, and his encouragement led me to create bots that significantly automated and improved our tasks.
Davis is a positive and motivating presence on any team. Just a few minutes of conversation with him can inspire anyone to strive for greater achievements. 
His supportive nature and willingness to share knowledge make him an invaluable asset to any organization. I'm grateful for the opportunity to have worked with such a talented and encouraging individual.
He will be an asset to any organisation and we already miss him in zoho . 😀`
    },
  ];

  return (
    <div className="about-reviews-container">
      {reviewsText.map((review, index) => (
        <div className="about-review-card" key={index}>
          <div className="about-review-card__tldr">
           “{review?.tldr || "Inspiring"}”
          </div>
          <div className="about-review-card__body">
            {review.review}
          </div>
          <div className="about-review-card__author">
            <div>
              <img className="about-review-card__author__img" src={'img/about-page/' + (review.profile_pic || "favicon.ico")} alt="img" />
            </div>
            <div className="about-review-card__author__info">
              <div className="about-review-card__author__name">{review.name}</div>
              <div className="about-review-card__author__tagline">{review.designation}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default About;