import React, { useRef, useEffect, useState } from 'react';
import './Confessions.css';

function Confessions() {
    const [textareaText, setTextareaText] = useState('Input text to be added to the image here');
    const [showToast, setShowToast] = useState(false);
    const [color, setColor] = useState('black');
    const [fontFamily, setFontFamily] = useState('Arial, sans-serif');
    const [sentiment, setSentiment] = useState('');

    const containerRef = useRef<HTMLDivElement | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    const handleImageGeneration = (content: string, currentColor: string) => {
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 2000);

        let fontSize = '20px';
        // Calculate fontsize based on the length of the text
        if (content.length < 100) {
            fontSize = '100px';
        } else if (content.length < 250) {
            fontSize = '70px';
        } else if (content.length < 350) {
            fontSize = '50px';
        } else if (content.length < 700) {
            fontSize = '35px';
        }
        const lineHeight = parseInt(fontSize, 10) * 1.2; // Adjust line height as needed
        // Generate a downloadable image with the color and text
        const canvas = document.createElement('canvas');
        canvas.width = 1080;
        canvas.height = 1080;
        const context = canvas.getContext('2d');
        if (context) {
            // Create a linear gradient
            // const gradient = context.createLinearGradient(0, 0, canvas.width, canvas.height);
            // gradient.addColorStop(0, color); // Start color
            // gradient.addColorStop(1, initialColor);  // End color

            // Use the gradient as the fill style
            context.fillStyle = currentColor;
            context.fillRect(0, 0, canvas.width, canvas.height);
            context.font = `${fontSize} ${fontFamily}`;

            context.textAlign = 'center';
            context.textBaseline = 'middle';
            context.fillStyle = ['#47122d', 'black'].includes(color) ? 'white' : 'black';

            // Padding values
            const paddingX = 20;
            const paddingY = 20;

            // Calculate the position
            const x = canvas.width / 2;
            // const y = (canvas.height - (lineHeight * 1.5)) / 2;
            const y = (canvas.height - (lineHeight)) / 2;

            // Function to split text into lines
            const getLines = (ctx, text, maxWidth) => {
                text = text.replaceAll('\n', ' #NEWLINE# ');
                const words = text.split(' ');
                const lines: string[] = [];
                let currentLine = words[0] != null ? words[0] : '';

                for (let i = 1; i < words.length; i++) {
                    // if there's a line break, push ' ' to the line
                    if (words[i] === '#NEWLINE#') {
                        lines.push(currentLine);
                        currentLine = '';
                        continue;
                    }
                    const word = words[i];
                    const width = ctx.measureText(currentLine + ' ' + word).width;
                    if (width < maxWidth) {
                        currentLine += ' ' + word;
                    } else {
                        lines.push(currentLine);
                        currentLine = word;
                    }
                }
                lines.push(currentLine);
                return lines;
            };

            // Split the content into lines
            const maxWidth = canvas.width - 2 * paddingX;
            const lines = getLines(context, content, maxWidth);

            // Draw each line with padding
            const startY = y - ((lines.length - 1) / 2) * lineHeight;

            lines.forEach((line, index) => {
                context.fillText(line, x, startY + index * lineHeight);
            });
            
            context.font = `35px Georgia, serif`;
            // make it bold
            context.font = 'bold ' + context.font;
            // make it italics
            context.font = 'italic ' + context.font;
            context.fillText('@zoho_confessions', x, canvas.height - (lineHeight * 0.5));

            canvasRef.current = canvas;
        }
    };

    const handlePreview = () => {
        updateCanvasPreview(color);
    }

    const updateCanvasPreview = (currentColor: string) => {
        handleImageGeneration(textareaText, currentColor);
        if (canvasRef.current) {
            if (containerRef.current) {
                containerRef.current.innerHTML = ''; // Clear any existing content
                containerRef.current.appendChild(canvasRef.current);
            }
        } else {
            alert('Nothing to display yet');
        }
    }
            
    const downloadCanvas = () => {
        handleImageGeneration(textareaText, color);
        if (canvasRef.current) {
            const dataURL = canvasRef.current.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = dataURL;
            link.download = 'confession' + Date.now() + '.png';
            link.click();
        }     
    }

    function handleSentiment(sentiment: string) {
        setSentiment(sentiment);
        let currentColor = color
        if (sentiment === 'relationship') {
            currentColor = '#f38598'
        } else if (sentiment === 'team') {
            currentColor = '#2b9eb3'
        } else {
            currentColor = '#e1e1e1'
        }
        setColor(currentColor)
        updateCanvasPreview(currentColor);
    }


    const getSentiment = async () => {
        try {
            const url = 'https://dvsj-gettextsentiment.web.val.run'
            const queryParamKey = 'inputText';
            const queryParamValue = textareaText;
            const fullUrl = `${url}?${queryParamKey}=${queryParamValue}`;
            const responseJson = await fetchData(fullUrl);
            const sentiment = responseJson.sentiment;
            handleSentiment(sentiment);
        } catch (error) {
            console.error('Error:', error);
            alert(error);
        }
    }

    async function fetchData(fullUrl: string) {
        try {
            const response = await fetch(fullUrl);
            const data = await response.json();
            console.log(data);
            return data;
        } catch (error) {
            console.error('Error:', error);
            return null;
        }
    }

    const handleTextareaChange = (event) => {
        setTextareaText(event.target.value);
    };
    const handleColorChange = (event) => {
        setColor(event.target.value);
    }
    const handleFontFamilyChange = (event) => {
        setFontFamily(event.target.value);
    }


    return (
        <div id='zoho-confessions'>
            <h1>Text kudu image edu</h1>
            <div>
                <textarea className="conf-textarea" value={textareaText} onChange={handleTextareaChange} />
            </div>
            <div id="confessions-options">
                <div className="confessions-option">
                    Background color
                    <select value={color} onChange={handleColorChange}>
                        <option value='black'>Black</option>
                        <option value='#ccc9d1'>Grey</option>
                        <option value='#f38598'>MutedPink</option>
                        <option value='#2b9eb3'>ZC-Blue</option>
                        <option value='green'>Green</option>
                        <option value='yellow'>Yellow</option>
                        <option value='red'>Red</option>
                        <option value='blue'>Blue</option>
                        <option value='white'>White</option>
                        <option value='#e54751'>MutedRed</option>
                        <option value='#e1e1e1'>MutedWhite</option>
                        <option value='#ACD8AA'>MutedGreen</option>
                        <option value='#FFEE6A'>MutedYellow</option>
                        <option value='#47122d'>MutedPurple</option>
                        <option value='#56CBF9'>MutedBlue</option>
                        <option value='#44AF69'>ZC-Green</option>
                        <option value='#fcab10'>ZC-Orange</option>
                        <option value='#f8333c'>ZC-Red</option>
                    </select>
                </div>
                <div className="confessions-option">
                    Font style
                    <select value={fontFamily} onChange={handleFontFamilyChange}>
                        <option value='Arial, sans-serif'>Arial</option>
                        <option value='Times New Roman, serif'>Times New Roman</option>
                        <option value='Courier New, monospace'>Courier New</option>
                        <option value='Georgia, serif'>Georgia</option>
                        <option value='Comic Sans MS, cursive'>Comic Sans</option>
                        <option value='Verdana, sans-serif'>Verdana</option>
                        <option value='Impact, Charcoal, sans-serif'>Impact</option>
                    </select>
                </div>
            </div>
            -----------
            <br/>
            <div id="confessions-actions">
                <button onClick={getSentiment} className='conf-btn'>
                    Get Sentiment
                </button>
                <button onClick={downloadCanvas} className='conf-btn'>
                    Download Image
                </button>
                <button onClick={handlePreview} className='conf-btn'>
                    View Preview
                </button>
            </div>
            {sentiment && <div id="sentiment-name">sentiment: {sentiment} (out of team, relationship & other)</div>}
            <div ref={containerRef} id="canvas-container"></div>
            
            {/* {showToast && <div className='toast'>Downloading!</div>} */}
        </div>
      );
}

export default Confessions;